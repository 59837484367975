import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {SafeHtml} from '@angular/platform-browser'
import {ConfigService} from './config.service'
import {WEB_SOCKET} from '../application/window.provider'
import {Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'

export interface IDreamMessage {
  sender: 'ad' | 'assistant' | 'problem' | 'user',
  text: string,
  html: WritableSignal<SafeHtml>
  complete: boolean
  prompt_tokens?: number
  completion_tokens?: number
}

export interface IChatMessage {
  content: string
  role: 'user' | 'assistant'
  ts: number
}

@Injectable({
  providedIn: 'root'
})
export class DreamService {
  public processing$ = signal<boolean>(true)
  public closed$ = signal<boolean>(false)
  public error$ = signal<boolean>(false)
  public ready$ = signal<number | null | undefined>(0)

  public ad$ = signal<number>(0)


  private configService = inject(ConfigService)
  private line: string = ''
  private startUl: boolean = false
  private startOl: boolean = false

  private lists: string[] = []

  private lastMessage: IDreamMessage = {
    text: 'Hej! Vad vill du laga idag?',
    html: signal<SafeHtml>(`<b>Hej! ${this.configService.loggedInUser$()?.name}</b> Vad vill du laga idag?`),
    sender: 'assistant',
    complete: true
  }
  public messages$ = signal<IDreamMessage[]>([this.lastMessage])

  public recipeDelivered = false

  private socketFactory = inject(WEB_SOCKET)
  private socket: WebSocket | null = null

  private lastPrompt: string | null = null

  private http = inject(HttpClient)

  constructor() {
    this.processing$.set(true)
    this.getHistory().subscribe({
      next: history => {
        history.reverse().forEach((item) => {
          const message = item.content.split('\n').map(l => this.format(l)).join('')
          this.messages$.set([...this.messages$(), {
            sender: item.role as any,
            text: item.content,
            html: signal<string>(message),
            complete: true
          }])
        })
        setTimeout(() => {
          this.processing$.set(false)
        })
      }
    })
  }

  public getHistory(): Observable<IChatMessage[]> {
    const url = `${environment.apiUrl}/dream/history`
    return this.http.get<IChatMessage[]>(url)
  }

  public startWs(): void {
    if (this.socket === null || this.socket.readyState > 1) {
      this.socket = this.socketFactory.create()
      this.monitor()
      this.socket.onclose = (() => {
        this.closed$.set(true)
        this.processing$.set(false)
      })
      this.socket.onerror = (() => {
        this.error$.set(true)
        this.processing$.set(false)
      })

      this.socket.onmessage = (event: { data: string }) => {
        try {
          const data = JSON.parse(event.data)
          this.handleMessage(data.message, data.complete)
        } catch { /** nvm */
        }
      }

      this.socket.onopen = () => {
        this.closed$.set(false)
        this.error$.set(false)
        if (this.lastPrompt !== null) {
          this.sendMessage(this.lastPrompt)
        }
      }
    }
  }

  public stopWs(): void {
    this.lastPrompt = null
    if (this.socket !== null) {
      this.socket.close()
    }
  }

  /**
   * Send the prompt and then wait for new results. We expect
   * ChatGPT to start babbling
   * @param prompt
   */
  public sendMessage(prompt: string) {

    this.lastPrompt = prompt
    if (!this.socket || this.socket.readyState > 1) {
      this.startWs()
      return
    }

    this.socket!.send(JSON.stringify({content: prompt, user: this.configService.loggedInUser$()?.sub}))
    this.processing$.set(true)

    const message: IDreamMessage = {
      sender: 'user',
      text: prompt,
      html: signal<string>(prompt),
      complete: true
    }

    this.lastMessage = {
      sender: 'assistant',
      text: '',
      html: signal<SafeHtml>(''),
      complete: false
    }
    this.messages$.set([...this.messages$(), message, this.lastMessage])
  }

  public handleMessage(message: string, complete: boolean) {
    // Letters or part of letters.
    this.lastPrompt = null
    this.line += message
    this.recipeDelivered = this.recipeDelivered || this.line.toLowerCase().includes('korv')
    if (complete) {
      this.processing$.set(false)
      this.setLine(this.line)
      this.line = ''
      this.addAdvert(this.recipeDelivered)
      return
    }

    if (this.line.includes('\n')) {
      this.setLine(this.line)
      this.line = ''
    }
  }

  public addAdvert(add: boolean): void {
    if (add) {
      this.recipeDelivered = false
      setTimeout(() => {
        this.processing$.set(true)
        const message: IDreamMessage = {
          sender: 'ad',
          text: '',
          html: signal<string>(''),
          complete: true
        }
        this.ad$.set(Math.floor(Math.random() * (7) + 1))
        this.messages$.set([...this.messages$(), message])
        setTimeout(() => {
          this.processing$.set(false)
        }, 200)
      }, 2000)
    }
  }

  /**
   * Making this public, move to its own class later.
   * Consider a 3rd party processor.
   * @param line
   */
  public format(line: string): string {
    let item = line.trim()
    let prepend = ''

    // Bold (**)
    let match = item.match(/(.*)\*{2}(.*)\*{2}(.*)/)
    while (match) {
      item = `${match[1]}<b>${match[2]}</b> ${match[3]}`
      match = item.match(/(.*)\*{2}(.*)\*{2}(.*)/)
    }

    // List items.
    const isItemLine = item.charAt(0) === '-'

    // If we are inside a numbered list, we make items to paragraphs.
    if (isItemLine && this.startOl) {
      return `<p>${item.replace('-', '').trim()}</p>`
    }


    if (!this.startUl && isItemLine) {
      this.startUl = true
      /*let prelist = ''
      if (this.lists.length > 0) {
        prelist = '<li>'
      }*/
      this.lists.push('ul')
      return `<ul><li>${item.replace('-', '').trim()}</li>`
    }

    if (this.startUl && isItemLine) {
      return `<li>${item.replace('-', '').trim()}</li>`
    }

    if (this.startUl && !isItemLine) {
      this.startUl = false
      // Its eiter an outer list or an inner list
      this.lists.pop()
      prepend = `</ul>`
    }

    // Numbered item a digit followed by a dot and a space (1. )
    match = item.match(/^(\d{1,2})\. (.*)$/)
    if (match) {
      if (!this.startOl) {
        this.startOl = true
        this.lists.push('ol')
        return `${prepend}<ol><li>${match[2]}</li>`
      }
      return `<li>${match[2]}</li>`
      // If just an empty line we do not stop the list
    } else if (this.startOl && item) {
      this.startOl = false
      prepend += `</${this.lists.pop()}>`
    }

    // Find number of "heading markers" (#) and make a heading if so.
    match = item.match(/^(#{1,4}) (.*)$/)
    if (match) {
      return `${prepend}<h${match[1].length}>${match[2]}</h${match[1].length}>`
    }
    return `${prepend}<p>${item}</p>`
  }

  private setLine(text: string) {
    this.lastMessage.text = text
    this.lastMessage.html.set(this.lastMessage.html() + this.format(text))
  }

  private monitor(): void {
    setTimeout(() => {
      this.ready$.set(this.socket?.readyState)
      this.monitor()
    }, 500)
  }
}