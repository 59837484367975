@if (configService.gptAdmin$()) {
  <mat-divider></mat-divider>
  @if (prompt()) {
    <strong>Prompt</strong>
    {{ prompt() }}
  }
  @if (imagePrompt()) {
    <strong>Bildprompt</strong>
    {{ imagePrompt() }}
  }
  @if (promptError()) {
    <strong>GPT Error</strong>
    {{ promptError() }}
  }
}