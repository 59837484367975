import {Component, HostListener, inject} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {DOCUMENT} from '@angular/common'
import {SwUpdateService} from './services/sw-update.service'

@Component({
  selector: 'eln-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet],
  providers: [{provide: DOCUMENT, useValue: document}]
})
export class AppComponent {

  private service = inject(SwUpdateService)
  private document = inject(DOCUMENT)

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (this.document.hidden) {
      this.service.pauseUpdateCheck()
    } else {
      this.service.startUpdateCheck()
    }
  }

  constructor() {
    this.service.startUpdateCheck()
  }
}
