import {AsyncPipe} from '@angular/common'
import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatDialog} from '@angular/material/dialog'
import {MatDivider} from '@angular/material/divider'
import {MatIcon} from '@angular/material/icon'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {filter, switchMap} from 'rxjs'
import {SplitPipe} from '../../../pipes/split.pipe'
import {UserService} from '../../../services/user.service'
import {AvatarComponent} from '../../common/avatar/avatar.component'
import {AddUserDialogComponent} from './add-user-dialog/add-user-dialog.component'
import {FamilySettingsComponent} from './settings/family-settings.component'
import {User} from '../../../utils/user.class'
import {InviteUserDialogComponent} from './invite-user-dialog/invite-user-dialog.component'
import {DEFAULT_DIALOG_STYLE} from '../../../application/constants'

@Component({
  selector: 'eln-family',
  imports: [
    MatIcon,
    MatDivider,
    SplitPipe,
    AsyncPipe,
    FamilySettingsComponent,
    MatButton,
    AvatarComponent,
    MatProgressSpinner,
    MatCard
  ],
  templateUrl: './family.component.html',
  styleUrl: './family.component.scss'
})
export class FamilyComponent {
  protected userService: UserService = inject(UserService)
  readonly dialog: MatDialog = inject(MatDialog)

  public switchUser(id: string): void {
    this.userService.switchUser(id)
  }

  public addUser(): void {
    AddUserDialogComponent.open(this.dialog)
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((user: User) => {
          user.familyId = this.userService.me$().familyId
          return this.userService.addUser(user)
        })
      ).subscribe()
  }

  public invite(): void {
    this.dialog.open(InviteUserDialogComponent, {
      ...DEFAULT_DIALOG_STYLE
    })
  }
}
