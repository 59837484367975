import {Component, inject, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {UserService} from '../../../services/user.service'

@Component({
  selector: 'eln-load',
  template: ''
})
export class LoadComponent implements OnInit {

  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private service = inject(UserService)

  public ngOnInit() {
    this.route.queryParamMap
      .pipe()
      .subscribe({
        next: params => {
          if (params.get('invite')) {
            this.service.invite$.next(params.get('invite')!)
          }
          return this.router.navigate(['external'])
        }
      })
  }
}
