import {AsyncPipe, DatePipe, TitleCasePipe} from '@angular/common'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {Router, RouterLink} from '@angular/router'
import {FamilyMenuDay, IFamilyRecipe, sortByDay} from '@ellen/user-be'
import {Subscription} from 'rxjs'
import {
  CREATE_MENU_PLAN_ROUTE,
  CREATE_MENU_ROUTE,
  CREATE_QUICK_MENU_ROUTE,
  INTERNAL_MODULE,
  MY_COOKBOOK_ROUTE,
  MY_FAMILY_ROUTE,
  RECIPE_ROUTE
} from '../../../application/constants'
import {RecipesService} from '../../../services/recipes.service'
import {UserService} from '../../../services/user.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {RecipeContentComponent} from '../recipe/recipe-content/recipe-content.component'
import {
  CreateMenuWarningDialogComponent
} from '../create-menu/create-menu-warning-dialog/create-menu-warning-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {DetailedFamily} from '../../../utils/detailed-family.class'

@Component({
  selector: 'eln-my-cookbook',
  templateUrl: './my-cookbook.component.html',
  styleUrl: './my-cookbook.component.scss',
  imports: [
    RouterLink,
    MatButton,
    AsyncPipe,
    RecipeContentComponent,
    DatePipe,
    TitleCasePipe,
    AppAddsComponent
  ]
})
export class MyCookbookComponent implements OnInit, OnDestroy {

  protected readonly INTERNAL_MODULE = INTERNAL_MODULE
  protected readonly FAMILY_ROUTE = MY_FAMILY_ROUTE
  protected readonly CREATE_MENU_ROUTE = CREATE_MENU_ROUTE
  protected readonly CREATE_MENU_PLAN_ROUTE = CREATE_MENU_PLAN_ROUTE
  protected readonly CREATE_QUICK_MENU_ROUTE = CREATE_QUICK_MENU_ROUTE

  public schedule: FamilyMenuDay[] = []

  public userService: UserService = inject(UserService)
  public recipesService: RecipesService = inject(RecipesService)
  private dialog = inject(MatDialog)

  private router: Router = inject(Router)

  private sub$ = new Subscription()

  public ngOnInit() {
    this.sub$ = this.userService.family$
      .subscribe((family: DetailedFamily) => {
        if (family.familyMembers.some(u => !u.isValid)) {
          CreateMenuWarningDialogComponent.open(this.dialog)
          return
        }
        // Sort family schedule by day
        this.schedule = family.schedule?.sort((a, b) =>
          sortByDay(a.day, b.day)) ?? []
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public onTitleClick(menuDay: FamilyMenuDay, familyRecipe: IFamilyRecipe) {
    this.router
      .navigate([INTERNAL_MODULE, MY_COOKBOOK_ROUTE,
        RECIPE_ROUTE, menuDay.day, familyRecipe.meal])
      .then()
  }

  public onDeleteClick(menuDay: FamilyMenuDay, familyRecipe: IFamilyRecipe) {
    this.recipesService.removeFamilyRecipe(menuDay.day, familyRecipe.meal)
      .subscribe()
  }
}
