import {NgClass} from '@angular/common'
import {AfterViewChecked, Component, effect, ElementRef, inject, OnDestroy, OnInit, viewChild} from '@angular/core'
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatFormField} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {DreamService, IDreamMessage} from '../../../services/dream.service'
import {AdvertComponent} from './advert/advert.component'

@Component({
  selector: 'eln-dream',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatProgressSpinner,
    MatFormField,
    MatInputModule,
    MatIcon,
    AdvertComponent
  ],
  templateUrl: './dream.component.html',
  styleUrl: './dream.component.scss'
})
export class DreamComponent implements AfterViewChecked, OnInit, OnDestroy {
  private chatWindow = viewChild.required<ElementRef>('chatWindow')

  private chatArea = viewChild.required<ElementRef>('chatArea')

  public userInput: FormControl = new FormControl<string>('', {nonNullable: true})
  public maxTextLength = 250


  /**
   * Public for testing only
   */
  public idle = true

  protected readonly service = inject(DreamService)

  constructor() {
    effect(() => {
      if (!this.service.processing$()) {
        this.idle = true
        this.userInput.enable()
        this.chatArea().nativeElement.focus()
        setTimeout(() => {
          this.scrollToBottom()
        }, 1)
      }
    })
  }

  public ngOnInit() {
    this.service.startWs()
  }

  public ngOnDestroy() {
    this.service.stopWs()
  }

  public getClass(message: IDreamMessage) {
    if (message.sender === 'user') {
      return 'user-message'
    }
    if (message.sender === 'ad') {
      return 'ad-message'
    }
    return 'assistant-message'
  }

  public sendMessage(): void {
    if (this.userInput.value.trim()) {
      this.idle = false
      this.service.sendMessage(this.userInput.value.trim())
      this.userInput.reset()
      this.userInput.disable()
    }
  }

  public ngAfterViewChecked(): void {
    if (!this.idle) {
      this.scrollToBottom()
    }
  }

  /**
   * Scroll to bottom when we get new messages (so that user don't need to)
   * @private
   */
  private scrollToBottom(): void {
    const chatWindowElement = this.chatWindow().nativeElement
    chatWindowElement.scrollTop = chatWindowElement.scrollHeight
  }
}
