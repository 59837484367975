import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {DEFAULT_DIALOG_STYLE} from '../../../../application/constants'
import {ConfigService} from '../../../../services/config.service'
import {UserService} from '../../../../services/user.service'

@Component({
  selector: 'eln-close-account-dialog',
  imports: [
    MatButton,
    MatProgressSpinner,
    MatDialogClose
  ],
  templateUrl: './close-account-dialog.component.html',
  styleUrl: './close-account-dialog.component.scss'
})
export class CloseAccountDialogComponent {

  protected isRemovingUser: boolean = false

  private dialogRef = inject(MatDialogRef<CloseAccountDialogComponent>)
  private userService = inject(UserService)
  private configService = inject(ConfigService)

  public static open(dialog: MatDialog): MatDialogRef<CloseAccountDialogComponent> {
    return dialog.open(CloseAccountDialogComponent, DEFAULT_DIALOG_STYLE)
  }

  public onRemoveClick(): void {
    this.isRemovingUser = true

    // Remove account
    this.userService.closeAccount()
      .subscribe(() => {
        this.dialogRef.close()
        this.isRemovingUser = false
        // Once request is completed, logout and reset all.
        this.configService.logout()
      })
  }
}
