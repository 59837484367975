<div class="dream-machine-holder" #chatWindow>
  @if (!(service.messages$().length > 0)) {
    <div class="dream-machine-loading-holder">
      <mat-spinner></mat-spinner>
    </div>
  }
  @for (message of service.messages$(); track $index; let last = $last) {
    <div class="dream-machine-message-holder"
         [ngClass]="getClass(message)">
      @if (message.sender === 'ad') {
        <eln-advert [image]="'assets/' + service.ad$() + '.png'"></eln-advert>
      } @else {
        <div class="message">
          <div [innerHTML]="message.html()">
          </div>

          @if (service.processing$() && last) {
            <div class="wait-for-gpt">
              <div class="ball1"></div>
              <div class="ball2"></div>
              <div class="ball3"></div>
            </div>
          }
        </div>

        <div class="arrow"></div>
        <div class="outer-arrow"></div>
      }
    </div>
  }
</div>

<div class="dream-machine-input-outer-holder">
  <div class="dream-machine-input-inner-holder">
    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="dream-machine-input">
        <textarea
          #chatArea
          class="dream-machine-input-textarea"
          (blur)="sendMessage()"
          [formControl]="userInput"
          [maxlength]="maxTextLength"
          (keyup.enter)="sendMessage()"
          [placeholder]="service.processing$() ? '' : 'Skriv här...'"
          matInput
        ></textarea>

      <mat-icon matIconSuffix
                (click)="sendMessage()"
                (keydown.enter)="sendMessage()"
                class="dream-machine-input-send"
                [class.active]="userInput.value?.length"
      >send
      </mat-icon>
    </mat-form-field>

    <div class="dream-machine-input-count">
      <div>
        {{ service.ready$() }}
      </div>
      <div
        (click)="service.startWs()"
        (keyup)="service.startWs()"
        tabindex="0">{{ service.closed$() ? 'C' : 'o' }}
      </div>
      <div
        (click)="service.stopWs()"
        (keyup)="service.stopWs()"
        tabindex="0">
        {{ service.error$() ? 'E' : '-' }}
      </div>

      {{ service.processing$() ? 'x' : '' }}
      {{ userInput.value?.length ?? '0' }}/{{ maxTextLength }}
    </div>
  </div>
</div>