import {Component, input} from '@angular/core'

@Component({
  selector: 'eln-advert',
  imports: [],
  templateUrl: './advert.component.html',
  styleUrl: './advert.component.scss'
})
export class AdvertComponent {
  public image = input.required<string>()
}
