<div class="family-close-account-holder">
  <div class="family-close-account-header">
    <img
      class="header-logo"
      alt="Joshu logo"
      src="assets/logo_joshu_black.png">
  </div>

  <span class="family-close-account-title">
    Är du säker på att du vill avsluta ditt konto?
  </span>

  <span class="family-close-account-body">
    Vid avslutning av konto kommer vi att radera dina personuppgifter enligt vår
    <a class="link">sekretesspolicy</a>.
  </span>
  <span class="family-close-account-body">
    Om du avslutar ditt konto kommer du inte längre kunna använda JOSHU.
  </span>

  <div class="family-close-account-actions">
    <button class="eln-btn-small" mat-raised-button color="warn"
            (click)="onRemoveClick()">
      @if (isRemovingUser) {
        <mat-spinner color="accent"></mat-spinner>
      } @else {
        Ja, avsluta mitt konto
      }
    </button>
    <button class="eln-btn-big" mat-raised-button color="accent"
            [mat-dialog-close]="null">
      Avbryt
    </button>
  </div>
</div>