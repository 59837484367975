import {Component, inject, OnInit} from '@angular/core'
import {MatCard} from '@angular/material/card'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {IRecipe, TDailyMeal, TDay} from '@ellen/user-be'
import {INTERNAL_MODULE, MY_COOKBOOK_ROUTE, ROUTE_PARAM_DAY, ROUTE_PARAM_MEAL} from '../../../application/constants'
import {RecipesService} from '../../../services/recipes.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {RecipeContentComponent} from './recipe-content/recipe-content.component'
import {AwsService} from '../../../services/aws.service'
import {UserService} from '../../../services/user.service'
import {switchMap} from 'rxjs/operators'
import {NEVER} from 'rxjs'

@Component({
  selector: 'eln-recipe',
  templateUrl: './recipe.component.html',
  styleUrl: './recipe.component.scss',
  imports: [
    MatProgressSpinner,
    RecipeContentComponent,
    MatCard,
    AppAddsComponent
  ]
})
export class RecipeComponent implements OnInit {

  public recipe: IRecipe | null = null

  protected recipesService = inject(RecipesService)
  protected awsService = inject(AwsService)
  public userService = inject(UserService)
  private route: ActivatedRoute = inject(ActivatedRoute)
  private router: Router = inject(Router)

  // We save parameters received in route to use later
  private day!: TDay
  private meal!: TDailyMeal

  public ngOnInit() {
    // Get recipe to be shown
    this.route.paramMap
      .subscribe((params) => {
        this.loadRecipeFromFamilySchedule(params)
      })
  }

  public onDiscardRecipeClick() {
    // Remove recipe and send user to "MyCookbook"
    this.recipesService.removeFamilyRecipe(this.day, this.meal)
      .subscribe(() => this.navigateToCookbook())
  }

  private loadRecipeFromFamilySchedule(params: ParamMap) {
    // This screen have two params when it is not being shown after a menu
    // generation, day and meal, which refer to a recipe within
    // family's schedule
    this.day = params.get(ROUTE_PARAM_DAY)! as TDay
    this.meal = params.get(ROUTE_PARAM_MEAL)! as TDailyMeal
    let recipe: IRecipe | null = null
    this.recipesService.getRecipe(this.day, this.meal)
      .pipe(switchMap(res => {
        recipe = res
        if (!recipe) {
          // If recipe was not found, send user back to "My Cookbook"
          this.navigateToCookbook()
          return NEVER
        }
        return this.awsService.getImageUrl(this.userService.me$().id, recipe.imageId!)
      }))
      .subscribe(image => {
        recipe!.imageUrl = image
        this.recipe = recipe
      })
  }

  private navigateToCookbook() {
    this.router
      .navigate(['/', INTERNAL_MODULE, MY_COOKBOOK_ROUTE])
      .then()
  }
}
