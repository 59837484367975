import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {ApplicationConfig, inject, isDevMode, provideAppInitializer, provideZoneChangeDetection} from '@angular/core'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation, withInMemoryScrolling} from '@angular/router'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'
import {ImagesService} from './services/images.service'
import {LOCAL_STORAGE, SIGNED_URL, WEB_SOCKET} from './application/window.provider'
import {getSignedUrl} from '@aws-sdk/s3-request-presigner'
import {provideServiceWorker} from '@angular/service-worker'
import {environment} from '../environments/environment'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withHashLocation(),
      //withDebugTracing(),
      withInMemoryScrolling({anchorScrolling: 'enabled'})),

    {provide: LOCAL_STORAGE, useValue: localStorage},
    provideAppInitializer(() => {
      const s = inject(ConfigService)
      return s.bootstrap()
    }),
    // Temp sneaky way to start the service, will fix
    provideAppInitializer(() => {
      const s = inject(ImagesService)
      return s.update()
    }),
    {
      provide: SIGNED_URL, useValue: getSignedUrl
    },
    {
      provide: WEB_SOCKET, useValue: {
        create: () => new WebSocket(environment.webSocketUrl)
      }
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
}
