import {inject} from '@angular/core'
import {map} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {Router} from '@angular/router'

export const authGuard = () => {
  const router = inject(Router)
  const configService: ConfigService = inject(ConfigService)
  // In other cases it is a good idea to route to other places
  // instead of just say yes or no.
  return configService.accessToken$
    .pipe(
      map(value => {
        if (value === null) {
          return router.navigate([''])
        }
        return !!value
      }))
}
