import {NgClass} from '@angular/common'
import {Component, input} from '@angular/core'
import {TDailyMeal} from '@ellen/user-be'
import {DailyMealPipe} from '../../../pipes/menu-meal.pipe'

@Component({
  selector: 'eln-meal-tag',
  templateUrl: './meal-tag.component.html',
  styleUrl: './meal-tag.component.scss',
  imports: [
    DailyMealPipe,
    NgClass
  ]
})
export class MealTagComponent {

  meal = input.required<TDailyMeal>()
}
