import {Component, inject, input} from '@angular/core'
import {ConfigService} from '../../../../services/config.service'
import {MatDivider} from '@angular/material/divider'

@Component({
  selector: 'eln-recipe-prompting',
  templateUrl: './recipe-prompting.component.html',
  styleUrl: './recipe-prompting.component.scss',
  imports: [
    MatDivider
  ]
})
export class RecipePromptingComponent {

  protected configService = inject(ConfigService)

  public prompt = input<string>()
  public imagePrompt = input<string>()
  public promptError = input<string>()
}
