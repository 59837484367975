import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCard} from '@angular/material/card'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {IShoppingListItem, TShoppingList} from '@ellen/user-be'
import {filter, Subscription, switchMap, tap} from 'rxjs'
import {UserService} from '../../../services/user.service'
import {AppAddsComponent} from '../../common/app-adds/app-adds.component'
import {ConfirmationDialogComponent} from '../../common/confirmation-dialog/confirmation-dialog.component'

type TShoppingListItemForm = FormGroup<{
  name: FormControl<string>
  checked: FormControl<boolean>
}>

@Component({
  selector: 'eln-shopping-list',
  imports: [
    MatButton,
    MatProgressSpinner,
    MatFormField,
    MatInput,
    MatIcon,
    MatSuffix,
    ReactiveFormsModule,
    MatCheckbox,
    MatCard,
    AppAddsComponent
  ],
  templateUrl: './shopping-list.component.html',
  styleUrl: './shopping-list.component.scss'
})
export class ShoppingListComponent implements OnInit, OnDestroy {

  public newItemForm: TShoppingListItemForm = new FormGroup({
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)]
    }),
    // We add "checked" parameter here even if not used in HTML for quick reads
    checked: new FormControl<boolean>(false, {nonNullable: true})
  })

  public shoppingList: TShoppingList = []
  public isListModified: boolean = false

  protected userService: UserService = inject(UserService)
  private dialog: MatDialog = inject(MatDialog)

  private shoppingListAsString: string = ''
  private sub$ = new Subscription()

  public ngOnInit() {
    this.sub$ = this.userService.family$
      .pipe(filter(Boolean))
      .subscribe((family) => {
        // Save a copy of current shopping list to "play" with it
        this.shoppingList = JSON.parse(JSON.stringify(family.shoppingList))
        this.sortShoppingList()
        // Update value of "shoppingListAsString" to check if anything has been
        // modified later. Also, reset modifications flag now
        this.shoppingListAsString = JSON.stringify(family)
        this.isListModified = false
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public addItem() {
    // Add new item and clear form
    this.shoppingList.push(this.newItemForm.getRawValue())
    this.newItemForm.reset()
    this.sortShoppingList()
    this.checkForChanges()
  }

  public removeItem(index: number) {
    this.shoppingList.splice(index, 1)
    this.checkForChanges()
  }

  public checkItem(item: IShoppingListItem, value: boolean) {
    item.checked = value
    this.checkForChanges()
  }

  public clearAllItems() {
    ConfirmationDialogComponent.open(this.dialog, {
      title: 'Alla artiklar i inköpslistan kommer att tas bort. Vill du fortsätta?',
      okButton: 'Okej'
    })
      .afterClosed()
      .pipe(
        // We only care when "ok" action is clicked
        filter(Boolean),
        // Remove all items from list and activate button (check for changes)
        tap(() => {
          this.shoppingList = []
          this.checkForChanges()
        }),
        // Save changes instantly. It is an important action
        switchMap(() => this.userService
          .saveFamilyShoppingList(this.shoppingList))
      )
      .subscribe()
  }

  public save() {
    this.userService.saveFamilyShoppingList(this.shoppingList)
      .subscribe()
  }

  private checkForChanges() {
    this.isListModified =
      JSON.stringify(this.shoppingList) !== this.shoppingListAsString
  }

  private sortShoppingList() {
    this.shoppingList = this.shoppingList
      .sort((a, b) =>
        a.name.localeCompare(b.name))
  }
}
